/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from 'react';

import CdSelect from '../Select/Select';
import { Input } from '../Input/Input';

interface ActionBarProps {
  currentSendingDomain: string;
  fromAddress: string;
  sendingDomains: string[];
  remainingCredits: number;
  updatedOn: string | undefined;
  handleRunPreview: (sendingDomain: string, fromAddress: string) => void;
}

export function isValidEmail(email: string) {
  const emailRegex =
    /^(?!.*\.\.)^[^.][a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?<!\.)@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+(?:[A-Za-z]{2,}|(?:\d{1,3}\.){1,3}\d{1,3}|localhost)$/;
  return emailRegex.test(email);
}

export const ActionBar = ({
  currentSendingDomain,
  fromAddress,
  sendingDomains,
  remainingCredits,
  updatedOn,
  handleRunPreview,
}: ActionBarProps) => {
  enum SendingDomainState {
    HasSendingDomains,
    IsSingleSendingDomain,
    NoSendingDomain,
  }
  const [selectedSendingDomain, setSelectedSendingDomain] = useState('');
  const [updatedFromAddress, setUpdatedFromAddress] = useState(fromAddress);
  const [valid, setValid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');

  const sendingDomainStatus = useMemo(() => {
    if (sendingDomains.length > 1) {
      return SendingDomainState.HasSendingDomains;
    } else if (sendingDomains.length === 1) {
      return SendingDomainState.IsSingleSendingDomain;
    } else {
      return SendingDomainState.NoSendingDomain;
    }
  }, [sendingDomains]);

  useEffect(() => {
    if (currentSendingDomain) {
      setSelectedSendingDomain(currentSendingDomain);
    }

    if (sendingDomainStatus === SendingDomainState.IsSingleSendingDomain) {
      setSelectedSendingDomain(sendingDomains[0] ?? '');
    }
  }, [currentSendingDomain, sendingDomainStatus]);

  useEffect(() => {
    const updateLastUpdated = () => {
      if (updatedOn) {
        const date = new Date(updatedOn);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        };
        const humanReadableDate = date.toLocaleString('en-US', options);
        setLastUpdated(humanReadableDate);
      } else {
        setLastUpdated('N/A');
      }
    };
    updateLastUpdated();
  }, [updatedOn]);

  useEffect(() => {
    if (remainingCredits <= 0) {
      setValid(false);
    } else {
      setValid(false); // always start with false and check for valid conditions
      if (sendingDomainStatus === SendingDomainState.NoSendingDomain && updatedFromAddress !== '') {
        if (isValidEmail(updatedFromAddress)) {
          setValid(true);
          setEmailError('');
        } else {
          setValid(false);
          setEmailError('Enter a valid email address.');
        }
      } else if (
        sendingDomainStatus === SendingDomainState.HasSendingDomains &&
        selectedSendingDomain === ''
      ) {
        setValid(false);
      } else if (
        (selectedSendingDomain !== '' && sendingDomains.length > 0) ||
        updatedFromAddress !== ''
      ) {
        setValid(true);
      }
    }
  }, [remainingCredits, sendingDomainStatus, selectedSendingDomain, updatedFromAddress]);

  const handleUpdatedFromAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedFromAddress(event.target.value);
  };

  const handleSendingDomainChange = (value: string) => {
    setSelectedSendingDomain(value);
  };

  const renderSendingDomainOptions = useMemo(() => {
    switch (sendingDomainStatus) {
      case SendingDomainState.HasSendingDomains:
        return (
          <>
            <option
              value=""
              disabled
            >
              Please select a sending domain
            </option>
            {sendingDomains.map((domain) => (
              <option
                key={domain}
                value={domain}
              >
                {domain}
              </option>
            ))}
          </>
        );
      case SendingDomainState.IsSingleSendingDomain:
        return (
          <option
            value={sendingDomains[0]}
            disabled
          >
            {sendingDomains[0]}
          </option>
        );
      default:
        return null;
    }
  }, [sendingDomainStatus, sendingDomains]);

  return (
    <div
      data-testid="action-bar"
      className="toolbar flex justify-between md:items-center sm:gap-2 px-4 py-2"
    >
      <p className="flex-grow">Showing results from latest preview: {lastUpdated || 'N/A'}</p>

      <div className="flex justify-between flex-grow">
        {sendingDomainStatus !== SendingDomainState.NoSendingDomain ? (
          <div className="flex items-center justify-center gap-2">
            {' '}
            <label className="font-medium">Sending From </label>
            <div className="relative inline-block">
              <CdSelect
                preset={selectedSendingDomain}
                options={null}
                onChange={handleSendingDomainChange}
              >
                {' '}
                {renderSendingDomainOptions}
              </CdSelect>
            </div>
          </div>
        ) : (
          sendingDomainStatus === SendingDomainState.NoSendingDomain && (
            <div className="flex items-center justify-center gap-2">
              <label
                className="text-neutral-700 text-base font-semibold"
                htmlFor="from-address"
              >
                Spam Filter Test Email
              </label>
              <div className="flex-col">
                <Input
                  value={updatedFromAddress}
                  onValueChange={handleUpdatedFromAddress}
                  error={emailError}
                />
              </div>
            </div>
          )
        )}
      </div>

      <button
        className={`pl-8 pr-8 pt-3 pb-3 hover:bg-sky-700 btn-background border border-blue-400 text-white text-base leading-none ${
          !valid ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        disabled={!valid}
        onClick={() => handleRunPreview(selectedSendingDomain ?? '', updatedFromAddress)}
      >
        Run New Inbox Preview
      </button>
    </div>
  );
};
