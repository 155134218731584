import { type DeviceDetails } from '../../services/device-casings/DeviceDetails.js';
import { Modal } from '../';
import { useEffect, useState } from 'react';
import { ClickLoadingIndicator } from '../';
import { onKeyDown, scrollDown, scrollUp, resetYPos } from '../../phone.js';
import downChevron from '../../assets/images/down-chevron.svg';
import upChevron from '../../assets/images/up-chevron.svg';

export interface FullScreenDevicePreviewProps {
  name?: string;
  fullUrl: string;
  deviceDetails: DeviceDetails | undefined;
  onClose?: () => void;
}

export function FullScreenDevicePreview({
  name,
  fullUrl,
  deviceDetails,
  onClose,
}: FullScreenDevicePreviewProps) {
  const [isOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    setIsOpen(false);
    onClose && onClose();
    resetYPos()
  };

  let transparentBg = false;

  const [deviceWidth, deviceHeight] = [
    deviceDetails?.screenWidth ?? null,
    deviceDetails?.screenHeight ?? null,
  ];
  let styledContainer = null;
  const [scale, setScale] = useState(0.8);

  if (deviceDetails) {
    const { screenHeight, screenWidth, deviceBorderRadius } = deviceDetails;

    const {
      backgroundDeviceUrl,
      containerHeight,
      containerLeft,
      containerTop,
      containerWidth,
      bgXPos,
      bgYPos,
      borderRadius,
    } = deviceDetails.container;
    if (backgroundDeviceUrl) {
      transparentBg = true;
    }

    styledContainer = (
      <>
        <div style={{ transform: `scale(${scale})` }}>
          <div
            id="thirdparty_litmus_mobile_screen"
            style={{
              background: `url(${backgroundDeviceUrl}) no-repeat ${bgXPos} ${bgYPos}`,
              width: screenWidth,
              height: screenHeight,
              position: 'relative',
              marginTop: '1em',
              borderRadius: deviceBorderRadius ?? 0,
            }}
          >
            <div
              id="thirdparty_litmus_mobile_container"
              style={{
                overflow: 'hidden',
                width: containerWidth,
                position: 'absolute',
                left: containerLeft,
                top: containerTop,
                height: containerHeight,
                borderRadius: borderRadius,
              }}
            >
              <div id="thirdparty_litmus_screen">
                <img
                  src={fullUrl}
                  id="thirdparty_litmus_screenimg"
                  style={{ position: 'absolute' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div
            className="flex top-0 right-0"
            role="controls"
            style={{
              position: 'fixed',
              right: 0,
              top: '15vw',
              marginRight: '4rem', // Optionally add margin to create some space from the edge
            }}
          >
            <div className="p-5 flex flex-col gap-2">
              <button
                onClick={scrollUp}
                className=" bg-black mt-16 p-3 border-blue-500 border-2 rounded-lg"
                style={{ marginLeft: '2px' }}
              >
                <img
                  src={upChevron}
                  alt="up-chevron"
                />
              </button>
              <button
                onClick={scrollDown}
                className=" p-3 border-blue-500 border-2 bg-black rounded-lg "
                style={{}}
              >
                <img
                  src={downChevron}
                  alt="down-chevron"
                />
              </button>
            </div>
            <div className="p-5 flex flex-col gap-2 w-64 ">
              <p className="text-white glow-text filter border-blue-500 border-2">
                You can either use up and down arrows on your keyboard or use the buttons to scroll
                the device content.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.src = fullUrl;
    image.onload = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    };
  }, [fullUrl]);

  useEffect(() => {
    const updateScale = () => {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (!deviceWidth || !deviceHeight) {
        setScale(1);
        return;
      }

      if (typeof parseInt(deviceWidth) == 'number' && typeof parseInt(deviceHeight) == 'number') {
        // Calculate scale factor based on the viewport and device dimensions
        const widthScale = viewportWidth / parseInt(deviceWidth);
        const heightScale = viewportHeight / parseInt(deviceHeight);

        // Set the scale factor to the smaller of the two, so the entire device fits
        setScale(Math.min(widthScale, heightScale) * 0.9);
      }
    };

    // Update scale factor on window resize
    window.addEventListener('resize', updateScale);

    // Initial scale calculation
    updateScale();

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [deviceWidth, deviceHeight]);

  return (
    <>
      {isLoading ? (
        <ClickLoadingIndicator
          isLoading={isLoading}
          type={'page'}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          title={name || 'Untitled'}
          transparent={transparentBg}
        >
          <div className="flex justify-center h-screen">
            <div
              className="flex items-center justify-center flex-grow" // Add justify-center to center the content horizontally
              onKeyDown={onKeyDown}
              data-testid="full-screen-device-preview"
            >
              {deviceDetails ? (
                styledContainer
              ) : (
                <div
                  className="bg-white"
                  style={{ overflowY: 'auto', maxHeight: '90vh' }}
                >
                  <img
                    data-testid="full-screen-device-preview-image"
                    className="mt-8"
                    src={fullUrl}
                    alt="fullUrl"
                  />
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
