import { useEffect, useState } from 'react';

import type { DevicePreview } from '../types/device-preview';
import { environment } from '@environment';

const endpoint = environment.EMAIL_PREVIEWS_API_URL;

const useDevicePreview = (
  accountKey: string,
  mode: string,
  id: string,
  refresh = false,
): [DevicePreview | null, boolean, (preview: DevicePreview) => void] => {
  const [devicePreview, setDevicePreview] = useState(null as unknown as DevicePreview);
  const [previewLoading, setLoading] = useState(true);

  if (!accountKey) {
    console.error('Account key is required to fetch device previews');
  }
  if (!mode) {
    console.error('Mode is required to fetch device previews');
  }
  if (!id) {
    console.error('Id is required to fetch device previews');
  }

  useEffect(() => {
    const fetchDevicePreviews = async () => {
      try {
        const creditEndpoint = `${endpoint}/device-preview/${mode}/${id}?accountKey=${accountKey}`;
        const response = await fetch(creditEndpoint, { credentials: 'include' });
        if (response.status === 404) {
          setDevicePreview(null as unknown as DevicePreview);
        } else {
          const data = await response.json();
          setDevicePreview(data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchDevicePreviews();
  }, [accountKey, mode, id, refresh]);

  return [devicePreview as DevicePreview, previewLoading, setDevicePreview];
};

export { useDevicePreview };
