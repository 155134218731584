import React, { useEffect, useState, type ReactNode } from 'react';

import downChevron from '../../assets/images/down-chevron.svg';

interface Option {
  value: string;
  label: string;
}

interface CdSelectProps {
  options: Option[] | JSX.Element | null;
  preset?: string;
  onChange: (value: string) => void;
  dropdownIconColor?: string;
  children: ReactNode;
}

const CdSelect: React.FC<CdSelectProps> = ({
  options,
  onChange,
  children,
  preset,
  dropdownIconColor = '#000000',
}) => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  useEffect(() => {
    if (preset) {
      setSelectedValue(preset);
    }
  }, [preset]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <div className="relative inline-block">
      <select
        value={selectedValue}
        onChange={handleChange}
        aria-label="Select an option"
        style={{ borderBottom: 'outset' }}
        className="appearance-none pr-20 text-gray-700 py-2 px-4 rounded border border-gray-300 focus:outline-none focus:bg-white focus:border-gray-500"
      >
        {children
          ? children
          : options &&
            Array.isArray(options) &&
            options.map((option: Option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
      </select>
      <span
        className="absolute top-1/2 right-1 transform -translate-y-1/2 pointer-events-none"
        style={{ color: dropdownIconColor }} // Custom color for the chevron icon
      >
        <img
          src={downChevron}
          alt="down-chevron"
        />
      </span>
    </div>
  );
};

export default CdSelect;
