import { useState } from 'react';

export function usePost<T>() {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<T | null>(null);

  const postData = async (path: string, body: unknown) => {
    setIsLoading(true);

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 404) {
        setError('Template does not exist. Please save your template before continuing.');
      } else {
        setError('Something went wrong, please try again.');
      }
    } else {
      const json = await response.json();
      setResponse(json);
    }
    setIsLoading(false);
  };

  return { postData, response, isLoading, error };
}
