import { ActionBar, ClickLoadingIndicator, TopBar } from '../../components';
import type { DevicePreviewResponse, GeneratePreviewPayload } from '../../types/device-preview';
import { InboxPreview, SpamFilter } from '../../pages';
import { QueryParamsContext, QueryParamsProvider } from '../../contexts/QueryParamsContext';
import { useCallback, useContext, useEffect, useState } from 'react';

import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import { SpamPreviewTestStatus } from '../../types/spam-preview';
import { TabbedContent } from '../../components';
import type { WorkerStateUpdate } from '../../types/worker-state';
import { environment } from '@environment';
import useCombinedLoading from '../../hooks/useCombinedLoader';
import { useDevicePreview } from '../../hooks/useDevicePreview';
import useGetSendingDomains from '../../hooks/useGetSendingDomains';
import { usePost } from '../../hooks/usePost';
import useRemainingCredits from '../../hooks/useGetRemainingCredit';
import useSpamFilter from '../../hooks/useSpamFilter';
import useSpamTestNotification from '../../hooks/useSpamTestNotification';

const tabs = [
  { tabId: 0, tabName: 'Inbox Preview' },
  { tabId: 1, tabName: 'Spam Filter' },
];

const AppContainer = () => {
  const [lastUpdated, setLastUpdated] = useState<string | undefined>('...');
  const [error, setError] = useState<string | undefined>();
  const {
    postData: generateInboxPreview,
    response: devicePreviewResponse,
    error: devicePreviewError,
    isLoading: generatingPreview,
  } = usePost<DevicePreviewResponse>();
  const { queryParamsState } = useContext(QueryParamsContext);
  const { accountKey, orgname, fromAddress, mode, id, subject, sessionId } = queryParamsState;
  const {
    remainingCredits,
    setRemainingCredits,
    isLoading: creditsLoading,
  } = useRemainingCredits(accountKey || '');

  const [workerStateStatus, setWorkerStateStatus] = useState<SpamPreviewTestStatus>(
    SpamPreviewTestStatus.UNKNOWN,
  );

  const [subscribed, setSubscribed] = useState(false);
  const subscriptionId = `${mode}-${id}`;
  const {
    connected: workerStateConnected,
    error: workerStateError,
    on: onWorkerStateConnect,
    subscribeToNotifications,
  } = useSpamTestNotification(subscriptionId, { accountKey, sessionId });

  const { sendingDomains, loading: domainsLoading } = useGetSendingDomains(accountKey || '');
  const [devicePreview, previewLoading, setDevicePreview] = useDevicePreview(accountKey, mode, id);
  const {
    spamPreview,
    spamLoading,
    error: spamError,
    currentSendingDomain,
    spamTestStatus,
    setSpamPreview,
  } = useSpamFilter(accountKey, mode, id, workerStateStatus);
  const combinedLoading = useCombinedLoading(spamLoading, creditsLoading, previewLoading);

  useEffect(() => {
    const connectionHandler = (data: unknown) => {
      const workerStateUpdate = data as WorkerStateUpdate;
      setWorkerStateStatus(workerStateUpdate.Status);
      if (workerStateUpdate.Status < 4) {
        console.log(
          'Spam Test Notification - Not Final State:',
          SpamPreviewTestStatus[workerStateUpdate.Status],
          'Fetching again..',
        );
      } else if (workerStateUpdate.Status >= 4) {
        console.log(
          'Spam Test Notification - Final State:',
          SpamPreviewTestStatus[workerStateUpdate.Status],
        );
      }
    };

    if (workerStateConnected) {
      onWorkerStateConnect(connectionHandler);
      if (!subscribed) {
        subscribeToNotifications();
        setSubscribed(true);
      }
    }

    if (workerStateError) {
      console.error('Error connecting to worker state', workerStateError);
    }
  }, [
    workerStateConnected,
    onWorkerStateConnect,
    subscribeToNotifications,
    subscribed,
    workerStateError,
    workerStateStatus,
  ]);

  useEffect(() => {
    // Updating data on fetching device preview
    if (devicePreview?.createdOn !== lastUpdated) {
      setLastUpdated(devicePreview?.createdOn);
    }
    if (devicePreviewResponse) {
      setRemainingCredits(devicePreviewResponse.creditsRemaining);
      setDevicePreview(devicePreviewResponse.devicePreview);
      setLastUpdated(devicePreviewResponse.devicePreview.createdOn);
    }
  }, [
    lastUpdated,
    devicePreview,
    devicePreviewResponse,
    setRemainingCredits,
    setDevicePreview,
    combinedLoading,
  ]);

  useEffect(() => {
    if (devicePreviewError) {
      setError(devicePreviewError);
    }
    if (spamError) {
      setError(spamError);
    }
    if (workerStateError) {
      setError(workerStateError);
    }
    console.error(error);
  }, [devicePreviewError, error, spamError, workerStateError]);

  const handleRunPreview = useCallback(
    async (selectedSendingDomain: string, latestFromAddress: string) => {
      setSpamPreview(null); // Clear spam preview on new inbox preview run
      const endpoint = `${environment.EMAIL_PREVIEWS_API_URL}/device-preview/${mode}/${id}?accountKey=${accountKey}`;
      const formData: GeneratePreviewPayload = {
        subject,
        sendingDomain: selectedSendingDomain,
        fromAddress: latestFromAddress,
      };
      if (remainingCredits > 0) {
        await generateInboxPreview(endpoint, formData);
      } else {
        console.error('You have no remaining credits');
      }
    },
    [setSpamPreview, mode, id, accountKey, subject, remainingCredits, generateInboxPreview],
  );

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = useCallback((tabId: number) => {
    setActiveTab(tabId);
  }, []);

  return (
    <QueryParamsProvider>
      <div className="app-container h-screen">
        <ClickLoadingIndicator
          isLoading={domainsLoading}
          type={'page'}
        />
        <TopBar
          orgName={orgname || ''}
          remainingCredits={remainingCredits}
        />
        <div className="action-bar-container pl-4 pr-4">
          {!domainsLoading && (
            <ActionBar
              currentSendingDomain={currentSendingDomain}
              fromAddress={fromAddress || ''}
              sendingDomains={sendingDomains || []}
              remainingCredits={remainingCredits}
              handleRunPreview={handleRunPreview}
              updatedOn={lastUpdated}
            />
          )}
        </div>
        <div className="tabs h-87vh">
          <TabbedContent
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          >
            {devicePreview ? (
              <InboxPreview
                devicePreviews={devicePreview}
                loading={previewLoading || generatingPreview}
              />
            ) : devicePreviewError ? (
              <ErrorPage error={devicePreviewError} />
            ) : (
              <span className="gray-500 text-xl">No previews found</span>
            )}
            {spamError ? (
              <span className="gray-500 text-xl">{spamError} </span>
            ) : (
              activeTab === 1 && (
                <SpamFilter
                  loading={spamLoading}
                  spamPreview={spamPreview}
                  testState={spamTestStatus}
                />
              )
            )}
          </TabbedContent>
        </div>
      </div>
    </QueryParamsProvider>
  );
};

export default AppContainer;
