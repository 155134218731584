import { createContext, useState, type ReactNode, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage/ErrorPage';

interface QueryParams {
  accountKey: string;
  orgname: string;
  sessionId: string;
  fromAddress: string;
  subject: string;
  mode: string;
  userlcid?: string;
  id: string;
}

export const QueryParamsContext = createContext<{
  queryParamsState: QueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<QueryParams>>;
}>({
  queryParamsState: {} as QueryParams,
  setQueryParams: () => {},
});

interface QueryParamsProviderProps {
  children: ReactNode;
}

export const QueryParamsProvider = ({ children }: QueryParamsProviderProps) => {
  const [searchParams] = useSearchParams();
  const queryParams = useMemo(() => {
    return {
      accountKey: searchParams.get('accountKey') || '',
      orgname: searchParams.get('orgname') || '',
      sessionId: searchParams.get('sessionId') || '',
      fromAddress: searchParams.get('fromAddress') || '',
      subject: searchParams.get('subject') || '',
      mode: searchParams.get('mode') === 'emailsend' ? 'email' : searchParams.get('mode') || '',
      id: (searchParams.get('id') || '').replace(/^\{|\}$/g, '').toLowerCase(),
    };
  }, [searchParams]);

  const isInvalidState = Object.entries(queryParams).some(
    ([key, value]) => key !== 'fromAddress' && value === '',
  );
  const [queryParamsState, setQueryParams] = useState(
    isInvalidState ? ({} as QueryParams) : queryParams,
  );

  return (
    <QueryParamsContext.Provider value={{ queryParamsState, setQueryParams }}>
      {isInvalidState ? (
        <ErrorPage error={'Invalid url! Try launching again from the email desginer.'} />
      ) : (
        children
      )}
    </QueryParamsContext.Provider>
  );
};
