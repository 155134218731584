import { FaArrowLeft } from 'react-icons/fa';
import type { ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  title: string; // Added name property
  onClose: () => void;
  children: ReactNode;
  transparent?: boolean;
}

export const Modal = ({ isOpen, title, onClose, children, transparent }: ModalProps) => {
  const closeModal = () => {
    onClose();
  };
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-1000 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
          <div className="fixed inset-0 z-1000 transition-opacity bg-gray-500 opacity-80"></div>
          <div
            className={`flex relative z-1000 p-6 mx-auto ${transparent ? 'bg-transparent' : 'bg-white'} rounded-lg shadow-xl`}
          >
            <div
              role="header"
              style={{ zIndex: 1001 }}
              className="bg-blue-500 py-2 fixed top-0 left-0 right-0 px-4 flex justify-between items-center header"
            >
              <FaArrowLeft
                onClick={closeModal}
                style={{ cursor: 'pointer' }}
              />
              <h2 className="text-white">{title}</h2>
              <button
                onClick={closeModal}
                className="bg-white text-gray-500"
                style={{ cursor: 'pointer' }}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ cursor: 'pointer' }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div
              className="mt-8 text-center z-1000"
              style={{ backgroundColor: 'transparent' }}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
